<template>
  <div v-bind="$props" id="final-page">
    <v-row align="center" justify="center" class="ma-0 text-center pa-0 text-h5 text-lg-h4 text-capitalize primary--text">{{ themeModel.thank_you_banner | textCapts}}</v-row>
    <v-row align="center" justify="center">
        <v-img contain class="ma-8" src="@/assets/icons/success-icon.png" width="90" height="90" />
    </v-row>
    <v-card-title v-if="!hideInspiredSec && !cardTokenId" class="text-capitalize primary--text px-0 font-weight-bold justify-center text-center text-subtitle-1 text-lg-h6">What inspired you to give today?</v-card-title>
    <v-row align="center" justify="center">
        <v-col cols="12" class="col-lg-8" v-if="!cardTokenId && !hideInspiredSec">
            <v-textarea hide-details="auto" name="Story Details" v-model.trim="storyDetails" outlined auto-grow rows="4" row-height="50"></v-textarea>
        </v-col>
        <v-col cols="12" class="col-lg-8 mb-4" align="center" justify="center" v-if="!cardTokenId && !hideInspiredSec">
            <v-btn type="button" color="secondary" rounded class="text-subtitle-1 font-weight-black" :class="{'px-10': storySubmit}"
            :loading="storySubmit" large @click.prevent="onSubmit">Submit
                <template v-slot:loader>
                    <v-progress-circular indeterminate :size="20" color="white"></v-progress-circular>
                    <span class="ml-2">Loading...</span>
                </template>
            </v-btn>
        </v-col>
        <v-col v-if="!cardTokenId && themeModel.show_like_button === 'On'" cols="12" class="col-lg-8" align="center" justify="center">
            <v-btn type="button" rounded color="#294a8b" align="center" dark>
                <ShareNetwork
                    network="facebook"
                    url="https://donaterefresh.thelight.com.au"
                    title=""
                    description=""
                    hashtags="lightfm">
                    <div class="d-flex align-center justify-center white--text text-capitalize font-weight-bold">
                        <div style="border-right: 1px solid white;" class="mr-2 pr-1">
                            <v-img  width="20" height="20" src="@/assets/icons/facebook-f.svg" />
                        </div>
                        <span>Share on Facebook</span>
                    </div>
                </ShareNetwork>
            </v-btn>
        </v-col>
        <v-col cols="12" v-if="!cardTokenId && themeModel.show_target === 'On'" class="my-8 col-lg-8">
            <v-progress-linear color="secondary" class="white--text"
            :value="currentCampaign && currentCampaign.progress ? currentCampaign.progress : 0"
            height="25"
            >
            <strong>Campaign Target &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                {{themeModel.target_mode === 'D' ? `$ ${currentCampaign && currentCampaign.remaining ? currentCampaign.remaining : 0}` : '' }}
                {{themeModel.target_mode === 'P' ? `${currentCampaign && currentCampaign.progress ? currentCampaign.progress : 0} %` : '' }}
            </strong>
            </v-progress-linear>
        </v-col>
        <v-col cols="12" class="col-lg-8" align="center" justify="center">
            <v-btn type="button" color="primary" rounded outlined class="font-weight-bold" :disabled="storySubmit" @click.prevent="resetForm" >Return to home page</v-btn>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from 'lodash';

export default {
    name: "FinalStep",
    emits: ["goHome"],
    data: (vm) => ({
        story_details: '',
        themeModel: {
            target_mode: 'P',
            show_like_button: 'On',
            show_target: 'On',
            thank_you_banner: 'your Gift has been processed'
        },
        hideInspiredSec: false,
        storySubmit: false,
        cardTokenId: vm.$route.params.tokenId
    }),
    computed: {
        ...mapGetters(["themeApiModel", "themeLoading", "currentCampaign"]),

        storyDetails: {
            get() {
                return this.story_details ? _.startCase(_.toLower(this.story_details)) : '';
            },
            set(newValue) {
                this.story_details = _.startCase(_.toLower(newValue.trim()));
            }
        }
    },
    watch: {
        themeApiModel: function(newValue) {
            if (newValue) {
                const valueThemeModel = this.$helpers.getValueOnlyObj(newValue);
                this.themeModel = {...this.themeModel, ...valueThemeModel};
            }
        }
    },
    filters: {
        textCapts: function(text) {
            if (text) {
                const caseWords = (text.trim()).split(' ').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
                return caseWords.replace('Thelight', 'TheLight');
            }
            return '';
        },
    },
    created() {
        const valueThemeModel = this.$helpers.getValueOnlyObj(this.themeApiModel);
        this.themeModel = {...this.themeModel, ...valueThemeModel};
        if (this.cardTokenId) this.themeModel.thank_you_banner = 'Your Card Has Been Saved';
    },
    mounted() {
        window.scrollTo(0,0);
        setTimeout(() => {
            this.resetForm();
        }, 300000);
    },
    methods: {
        ...mapActions(["storyFormSubmit"]),

        onSubmit () {
            this.storySubmit = true;
            const reqData = {
                donation_id: this.$helpers.getCurData('curDonate') ? this.$helpers.getCurData('curDonate') : null,
                story_details: this.story_details,
            }
            this.storyFormSubmit(reqData)
                .then((response) => {
                    this.$helpers.removeLSStorage('curDonate');
                    this.$store.commit('resetModel');
                    this.hideInspiredSec = true;
                    this.storySubmit = false;
                })
            .catch(() => this.storySubmit = false);
        },
        resetForm() {
            this.$helpers.removeLSStorage('curDonate');
            this.$store.commit('resetModel');
            window.location.reload();
        }
    }
};
</script>
